<template>
  <div>
    Logging you in... Please wait...
  </div>
</template>

<script>
import axios from 'axios'
import {mapActions} from 'vuex'

export default {
  name: 'TokenLogin',
  created () {
    this.loginFromToken({
      token: this.$route.params.token
    })
        .finally(() => {
          if (this.$route.params.redirect) {
            this.$router.push({name: this.$route.params.redirect})
          } else {
            this.$router.push({name: 'homepage'})
          }
        })
  },
  methods: {
    ...mapActions('auth', ['loginFromToken'])
  }
}
</script>

<style scoped>

</style>